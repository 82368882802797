import axios from 'axios';
import {
    createUserWithEmailAndPassword,
    fetchSignInMethodsForEmail,
    getAuth,
    GoogleAuthProvider,
    linkWithPopup,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut
} from "firebase/auth";
import {createContext, useContext} from "react";

const http = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

export type SignUpValues = {
    username: string;
    email: string;
    password: string;
}

export type ToDotUser = {
    _id: string;
    email: string;
    username: string;
    actions: Record<string, any>;
}

type AccountContextValue = {
    login(email: string, password: string): Promise<void>;
    logout(): Promise<void>;
    signUpWithEmail(signUpValues: SignUpValues): Promise<void>;
    signInWithGoogle(): Promise<void>;
    setUsername(username: string): Promise<void>;
}

const AccountContext = createContext<AccountContextValue>({
    login: async (email, password) => {
        await signInWithEmailAndPassword(getAuth(), email, password)
    },
    logout: async () => {
        await signOut(getAuth());
    },
    signInWithGoogle: async () => {
        try {
            await signInWithPopup(getAuth(), new GoogleAuthProvider());
        } catch (err: any) {

            switch (err.code) {

                case 'auth/account-exists-with-different-credential':
                    const methods = await fetchSignInMethodsForEmail(getAuth(), err.customData.email);
                    const credentials = await signInWithEmailAndPassword(getAuth(), 'blakgeek@gmail.com', prompt('enter password')!)
                    await linkWithPopup(credentials.user, new GoogleAuthProvider())
                    console.error({err, methods})
                    break;
            }
        }
    },
    signUpWithEmail: async ({email, password, username}: SignUpValues) => {
        const auth = getAuth();
        await createUserWithEmailAndPassword(auth, email, password);
        await http.post('/users', {username});
    },
    setUsername: async (username: string) => {
        await http.post('/users', {
            username
        }, {
            headers: {
                Authorization: `Bearer ${await getAuth().currentUser?.getIdToken(true)}`
            }
        });
    }
});

export const useAccount = () => useContext(AccountContext);
