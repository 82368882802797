import './ToDotPrinter.scss';
import React, {CSSProperties, ReactNode, useEffect, useState} from "react";
import classnames from "classnames";
import {chunk} from 'lodash';

import {ToDotQrCode} from "../ToDotQrCode";
import Screen from "../Screen/Screen";
import {getMyToDots, recordAction} from "../../services/api.service";
import {useNavigate} from "react-router-dom";
import {Layout, layouts} from "./Layouts";
import {PrintLayoutPreview} from "./PrintLayoutPreview";
import {Button} from "../Button";
import {useApp} from "../../contexts/AppContext";

const baseClassName = 'to-dot-printer';

export type StickersProps = {
    className?: string;
    children?: ReactNode;
}


export function ToDotPrinter({className, ...props}: StickersProps) {

    const navigate = useNavigate();
    const {recordUserAction} = useApp();
    const [toDots, setToDot] = useState<ToDot[]>([]);
    const [pages, setPages] = useState<ToDot[][]>([]);
    const [selectedLayout, setSelectedLayout] = useState<Layout | null>(null);
    const [printVars, setPrintVars] = useState<CSSProperties>({});


    useEffect(() => {
        ;(async () => {
            setToDot(await getMyToDots())
        })()
    }, [])

    function pagify({rows, columns, shape, size, rowGap, colGap, top, left}: Layout): void {


        setPrintVars({
            '--labelSize': `${size}in`,
            '--colGap': `${colGap}in`,
            '--rowGap': `${rowGap}in`,
            '--topPadding': `${top}in`,
            '--leftPadding': `${left}in`,
        } as CSSProperties);
        setPages(chunk(toDots, rows * columns));
    }

    async function handlePrintClick() {
        window.print();
        await recordUserAction('stickersPrinted')
    }

    function handleSelect(layout: Layout) {
        pagify(layout);
        setSelectedLayout(layout);
    }

    return (<>
        <Screen className={classnames(baseClassName, className)} {...props}>
            <Screen.Header>
                <h1>Print To-Dots</h1>
                <h2>Select a layout to print your To-Dot stickers.</h2>
            </Screen.Header>
            <Screen.Content className={`${baseClassName}__layouts`}>
                {layouts.map((layout, i) => (
                    <PrintLayoutPreview key={i}
                                        layout={layout}
                                        active={layout === selectedLayout}
                                        onClick={() => handleSelect(layout)}/>
                ))}
            </Screen.Content>
            <Screen.Actions>
                <Button icon={'check'} onClick={() => navigate(-1)}>Done</Button>
                <Button icon={'print'} disabled={!selectedLayout} onClick={handlePrintClick}>Print</Button>
            </Screen.Actions>
        </Screen>
        <div className={classnames(`${baseClassName}__printable-pages`)} style={printVars}>
            {pages.map((page) => (
                <div key={page[0]._id} className={`${baseClassName}__printable-page`}>
                    {page.map(toDot => (
                        <ToDotQrCode className={classnames(`${baseClassName}__printable-label`, {
                            [`${baseClassName}__printable-label--round`]: selectedLayout?.shape === 'round'
                        })}
                                     key={toDot._id}
                                     url={toDot.url ?? `https://todots.co/${toDot._id}`}/>
                    ))}
                </div>
            ))}
        </div>
    </>)
}


export default ToDotPrinter;
