import './SignUp.scss';
import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import classnames from "classnames";
import {useLocation, useNavigate} from "react-router-dom";
import {SignUpValues, useAccount} from "@services/account.service";
import Screen from "../../Screen/Screen";
import TextLogo from "@images/logo/check-name-hollow.svg";
import LinkButton from "../../Button/LinkButton";
import {Button} from "../../Button";

const baseClassName = 'sign-up';

export type SignUpProps = {
    className?: string;
    children?: ReactNode;
}

export function SignUp({className, ...props}: SignUpProps) {

    const {referrer = '/'} = useLocation().state ?? {};
    const navigate = useNavigate();
    const {signUpWithEmail, signInWithGoogle} = useAccount();
    const [account, setAccount] = useState<SignUpValues>({
        email: '',
        password: '',
        username: ''
    })
    const [missingData, setMissingData] = useState<boolean>(true);

    useEffect(() => {
        const {email, password, username} = account;
        setMissingData(!(email && password.length >= 6 && username.length > 3));
    }, [account])

    function handleValueChange({target}: ChangeEvent<HTMLInputElement>) {
        setAccount(account => ({
            ...account,
            [target.name]: target.value.trim()
        }))
    }

    async function handleSignUpClick() {
        try {
            await signUpWithEmail(account);
            navigate(referrer, {
                replace: true,
                state: {
                    referrer: '/'
                }
            });
        } catch(err) {
            alert('Sign In Failed.  Try Again.')
        }
    }

    async function handleGoogleSignUpClick() {
        try {
            await signInWithGoogle();
            navigate(referrer, {
                replace: true,
                state: {
                    referrer: '/'
                }
            });
        } catch(err) {
            alert('Sign In Failed.  Try Again.')
        }
    }

    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header className={`${baseClassName}__header`}>
            <h1>Sign Up</h1>
        </Screen.Header>
        <Screen.Content className={`${baseClassName}__content`}>
            <input value={account.username} name="username" type="text" onChange={handleValueChange} placeholder={'Choose A Username'}/>
            <input value={account.email} name="email" type="text" onChange={handleValueChange} placeholder={'Email'}/>
            <input value={account.password} name="password" type="password" onChange={handleValueChange} placeholder={'Password'}/>
        </Screen.Content>
        <Screen.Actions stacked>
            <Button onClick={handleSignUpClick} disabled={missingData}>Create Account</Button>
            <Button icon={'google'} onClick={handleGoogleSignUpClick}>Sign Up With Google</Button>
            <LinkButton to={'/sign_in'} replace state={{referrer}}>Got An Account? Sign In</LinkButton>
        </Screen.Actions>
    </Screen>
}

export default SignUp;
