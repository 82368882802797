import axios, {AxiosRequestConfig} from 'axios';
import {getAuth, User} from "@firebase/auth";
import {ToDotUser} from "./account.service";
import {imageKitConfig} from "../config/imageKit.config";
let user: User | null = null;

const http = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

http.interceptors.request.use(async (config) => {
    // Do something before request is sent
    config.headers = {
        ...config.headers,
        Authorization: await getAuth().currentUser?.getIdToken(),
    };
    return config
});

export type ToDotOptions = {
    details?: string;
    images?: string[];
}


export async function getMe(): Promise<ToDotUser> {
    const {data} = await http.get('/me');
    return {
        actions: {},
        ...data
    };
}

export async function createToDot(id: string, options: ToDotOptions): Promise<ToDot> {

    const {data} = await http.put(`/todots/${id}`, options);

    return data;
}

export async function getToDot(id: string): Promise<ToDot> {
    const {data} = await http.get(`/todots/${id}`);

    data.images = data.images?.map(toIkUrl) || [];
    if(data.toDone) {
        data.toDone.images = data.toDone.images?.map(toIkUrl) || [];
    }
    return data;
}

type ToDotCompletion = {
    description: string;
    images?: string[];
}

export async function addToDone(toDotId: string, details?: ToDotCompletion): Promise<ToDot> {
    const {data} = await http.post(`/todones`, {
        toDotId,
        ...details
    });
    return data;
}

export async function getMyToDots(exclude?: string): Promise<ToDot[]> {

    const {data} = await http.get<ToDot[]>('/my/todots', {
        params: {
            exclude
        }
    });
    return data.map(({images, ...toDot}) => ({
        ...toDot,
        images: images?.map(toIkUrl) ?? []
    }));
}

function toIkUrl(url: string) {
    // todo: add check to ensure it can be served though IK.  this really be happening on the server side.
    return `${imageKitConfig.baseUrl}/${url.replace(/https?:\/\/.*?\//, '')}`;
}

export async function getMyToDot(id: string): Promise<ToDot> {

    const {data} = await http.get(`/my/todots/${id}`);
    return data;
}

export async function recordAction(action: string, value?: any): Promise<void> {

    await http.post(`/my/actions/${action}`, {value})
}

export async function addToDotImageFiles(id: string, images: File[]) {

    await Promise.all(images.map(async (image) => {

        const mimeType = image.type;
        const uploadUrl = await getToDotImageUploadUrl(id, {
            mimeType
        });

        await axios.put(uploadUrl, image, {

            headers: {
                'Content-Type': mimeType,
                'x-amz-acl': 'public-read',
            }
        })
    }))
}

export async function addToDoneImageFiles(id: string, images: File[]) {

    await Promise.all(images.map(async (image) => {

        const mimeType = image.type;
        const uploadUrl = await getToDoneImageUploadUrl(id, {
            mimeType
        });

        await axios.put(uploadUrl, image, {

            headers: {
                'Content-Type': mimeType,
                'x-amz-acl': 'public-read',
            }
        })
    }))
}

export async function addBase64Images(id: string, images: string[]) {

    const files: File[] = await Promise.all(images.map(base64ToFile));
    return addToDotImageFiles(id, files);
}

type UploadUrlOptions = {
    filename?: string;
    encoding?: 'base64';
    mimeType?: 'image/png' | 'image/jpeg' | string;
}

export async function getToDotImageUploadUrl(id: string, options: UploadUrlOptions): Promise<string> {

    const {headers} = await http.post(`/todots/${id}/images`, options);

    return headers.location!;
}

export async function getToDoneImageUploadUrl(id: string, options: UploadUrlOptions): Promise<string> {

    const {headers} = await http.post(`/todones/${id}/images`, options);

    return headers.location!;
}

async function base64ToFile(base64: string): Promise<File> {
    const resp = await fetch(base64);
    const blob = await resp.blob();
    return new File([blob], 'dummy', {
        type: blob.type
    })
}