import React, {FC, ReactElement, ReactNode, useContext, useEffect, useState} from "react";
import {getAuth, User as FirebaseUser} from '@firebase/auth';
import {getMe, recordAction} from "@services/api.service";
import {ToDotUser} from "@services/account.service";
import useDetectKeyboardOpen from 'use-detect-keyboard-open'


type AppContextValue = {
    user?: ToDotUser;
    firebaseUser?: FirebaseUser | null;
    refreshUser: () => Promise<void>;
    recordUserAction: (action: string, value?: any) => Promise<void>
}

const AppContext = React.createContext<AppContextValue | undefined>(undefined);

type AppProviderProps = {
    children?: ReactNode;
}

export const useApp = (): AppContextValue => useContext(AppContext)!;


export const AppProvider: FC<AppProviderProps> = ({children}) => {

    const isKeyboardOpen = useDetectKeyboardOpen();
    const [initializing, setInitializing] = useState<boolean>(true)
    const [value, setValue] = useState<AppContextValue>({} as AppContextValue);

    useEffect(() => {
        if (isKeyboardOpen) {
            document.documentElement.classList.add('keyboard-open');
        } else {
            document.documentElement.classList.remove('keyboard-open');
        }
    }, [isKeyboardOpen])
    useEffect(() => {

        const auth = getAuth();
        const unsubscribeAuthStateChanged = auth.onAuthStateChanged(async (firebaseUser: FirebaseUser | null) => {

            let user: ToDotUser;
            if (firebaseUser) {
                try {
                    user = await getMe();
                } catch (err) {
                    user = {} as ToDotUser;
                }
            }
            setValue(value => ({
                ...value,
                user,
                firebaseUser,
                // I don't think this is the right implementation.  I should probably trigger a state change
                // when the actions are updated so that things can rerender based on the new information
                async recordUserAction(action, value = true) {
                    if (!user) return;
                    user.actions[action] = value;
                    await recordAction(action, value);
                },
                async refreshUser() {
                    const user = await getMe();
                    setValue(value => ({
                        ...value,
                        user
                    }));
                }
            }));
            setInitializing(false);
        });

        return () => {
            unsubscribeAuthStateChanged();
        }
    }, []);

    return (
        <AppContext.Provider value={value}>
            {initializing ? <></> : children}
        </AppContext.Provider>
    )
}