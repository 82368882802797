import './ToDotScanner.scss';
import React, {ReactNode, useState} from "react";
import classnames from "classnames";
import {useNavigate} from "react-router-dom";
import {QrInput, ValueChangeEvent} from "../QrInput/QrInput";
import {Screen} from '../Screen/Screen'
import TextLogo from "../../images/logo/check-name-hollow.svg";
import {Button} from "../Button";

const baseClassName = 'to-dot-scanner';

export type ToDotScannerProps = {
    className?: string;
    children?: ReactNode;
}

export function ToDotScanner({className, ...props}: ToDotScannerProps) {

    const navigate = useNavigate();

    async function handleScanComplete(e: ValueChangeEvent<string>) {
        const [url, host, id] = e.value.match(/^https:\/\/(.*?)\/.*?([\w,-]+)$/) ?? [];
        navigate(`/todot/${id}`, {replace: true});
    }


    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header className={`${baseClassName}__header`}>
            <h1>Scan</h1>
            <h2>Point your camera at a To-Dot.</h2>
        </Screen.Header>
        <Screen.Content>
            <QrInput onChange={handleScanComplete} autoStart stopAfterScan/>
        </Screen.Content>
        <Screen.Actions className={`${baseClassName}__actions`}>
            <Button icon={'cancel'} onClick={() => navigate(-1)}>Cancel</Button>
        </Screen.Actions>
    </Screen>
}

export default ToDotScanner;