import './MyToDots.scss';
import React, {ReactNode, useEffect, useState} from "react";
import classnames from "classnames";
import {getMyToDots} from "../../services/api.service";
import Screen from "../Screen/Screen";
import {Link, useNavigate} from "react-router-dom";
import LinkButton from "../Button/LinkButton";
import {Button} from "../Button";
import {ToDotCard, ToDotCardProps} from "./ToDotCard";

const baseClassName = 'my-to-dots';

export type MyToDotsProps = {
    className?: string;
    children?: ReactNode;
}

export function MyToDots({className, ...props}: MyToDotsProps) {

    const navigate = useNavigate();
    const [toDots, setToDots] = useState<ToDot[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [empty, setEmpty] = useState<boolean>(false);

    useEffect(() => {
        ;(async () => {
            const toDots = await getMyToDots('unused');
            setToDots(toDots);
            setEmpty(!toDots.length);
            setLoading(false);
        })();
    }, []);

    function handleToDotClick({_id}: ToDot) {
        navigate(`/todot/${_id}`);
    }

    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header>
            <h1>My To-Dots</h1>
        </Screen.Header>
        <Screen.Content className={`${baseClassName}__content`}>
            {toDots.map(toDot => (
                <ToDotCard toDot={toDot} key={toDot._id} onSelect={handleToDotClick}/>
            ))}
            <Screen.EmptyState show={empty} icon={'todots'}>
                <p>You haven't created any To-Dots yet.</p>
                <p>Tap the "Add A To-Dot" button below to create one now.</p>
            </Screen.EmptyState>
        </Screen.Content>
        <Screen.Actions>
            <Button icon={'close'} onClick={() => navigate(-1)}/>
            <LinkButton to={'/create'} icon={'add'}>Add A To-Dot</LinkButton>
        </Screen.Actions>
    </Screen>
}

export default MyToDots;