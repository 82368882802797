import './PicturesStep.scss';
import React, {ReactNode, useState} from "react";
import classnames from "classnames";
import {useNavigate, useOutletContext} from "react-router-dom";
import Screen from "../../Screen/Screen";
import {SetupWizardContext} from "../SetupWizard";
import {Button} from "../../Button";
import {CapturedImage, ImageChangeEvent, ImageInput} from "../../ImageInput";

const baseClassName = 'pictures-step';

export type PicturesStepProps = {
    className?: string;
    children?: ReactNode;
}



export function PicturesStep({className, ...props}: PicturesStepProps) {

    const navigate = useNavigate();
    const {onStepComplete} = useOutletContext<SetupWizardContext>();
    const [images, setImages] = useState<(CapturedImage)[]>([]);
    const [cameraOpen, setCameraOpen] = useState<boolean>(false);
    const [targetImageIndex, setTargetImageIndex] = useState<number>(0);

    function handleNextClick() {
        onStepComplete('pics', {
            images: images.map(({file}) => file)
        });
    }

    function handleThumbnailClick(index: number): void {
        // todo: enlarge image
        removeImage(index);
    }

    function removeImage(index: number) {
        console.log('removing image');
        setImages(images => images.filter((_, i) => i !== index))
    }

    async function handleNewPicture(e: ImageChangeEvent) {

        if (images.length >= 4 || !e.value) return;

        setImages(images => [...images, e.value!]);
    }

    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header className={`${baseClassName}__header`}>
            <h1>Pictures</h1>
            <h2>Add up to 4 images that show what needs to be done.</h2>
        </Screen.Header>
        <Screen.Content>
            <div className={`${baseClassName}__thumbnails`}>
                {images.map((image, index) => (
                    <div key={index}
                         className={classnames(`${baseClassName}__thumbnail`, {
                             [`${baseClassName}__thumbnail--unset`]: !image
                         })}
                         onClick={() => handleThumbnailClick(index)}
                    >
                        {image && <img src={image.url}/>}
                    </div>
                ))}
            </div>

        </Screen.Content>
        <Screen.Actions className={`${baseClassName}__actions`}>
            <Button icon={'cancel'} onClick={() => navigate(-1)}/>
            <ImageInput type={'file'} disabled={images.length > 3} onChange={handleNewPicture}/>
            <ImageInput type={'camera'} disabled={images.length > 3} onChange={handleNewPicture}/>
            <Button icon={'done'} onClick={handleNextClick}>Create</Button>
        </Screen.Actions>
    </Screen>


}

export default PicturesStep;