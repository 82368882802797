import './TextArea.scss';
import React, {ChangeEvent, ReactNode, TextareaHTMLAttributes, useEffect, useState} from "react";
import classnames from "classnames";
import {SpeechCaptureInput} from "@/components/SpeechCaptureInput";

const baseClassName = 'text-area';

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    label?: string;
}

export function TextArea({className, label, ...props}: TextAreaProps) {
    return <label className={classnames(baseClassName, className)}>
        <textarea className={`${baseClassName}__input`} {...props}/>
        {label && <div className={`${baseClassName}__label`}>{label}</div>}
    </label>
}


export type SpeechTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    label?: string;
}

export function SpeechTextArea({className, value, onChange, label, ...props}: TextAreaProps) {

    const [internalValue, setInternalValue] = useState<string | number | readonly string[] | undefined>(value);

    useEffect(() => {
        setInternalValue(value);
    }, [value])

    function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
        onChange?.(e);
    }

    function handleCapture(capturedText: string) {

        if(internalValue) {
            setInternalValue(internalValue => `${internalValue}\n\n${capturedText}`);
        } else {
            setInternalValue(capturedText);
        }
    }

    return <div className={classnames(baseClassName, className)}>
        <TextArea value={internalValue} label={label} onChange={handleChange} {...props}/>
        <SpeechCaptureInput className={`${baseClassName}__record-trigger`}
                            onCaptureComplete={handleCapture}/>
    </div>
}

export default TextArea;