import './ToDotViewer.scss';
import React, {ReactNode, useEffect, useState} from "react";
import classnames from "classnames";
import {useQuery, useQueryClient} from "react-query";
import {addToDone, getToDot} from "@/services/api.service";
import Screen from "../Screen/Screen";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import TextLogo from "../../images/logo/check-name-hollow.svg";
import {Button} from "../Button";
import Markdown from "react-markdown";
import {useModals} from "../Modal";
import {FormattedDate} from "@/components/FormattedDate";

const baseClassName = 'to-dot-viewer';

export type ToDotViewerProps = {
    className?: string;
    children?: ReactNode;
}

export function ToDotViewer({className, ...props}: ToDotViewerProps) {

    const {referrer = -1} = useLocation().state ?? {}
    const navigate = useNavigate();
    const {confirm} = useModals();
    const {id} = useParams<{ id: string }>();
    const [toDot, setToDot] = useState<ToDot | null>(null);
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        ;(async () => {
            try {
                setToDot(await getToDot(id!));
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    async function handleCompleteClick() {
        try {
            if (await confirm({
                title: 'To-Done It?',
                content: <>
                    <p>Did you complete this To-Dot?</p>
                </>,
                yesLabel: 'Yep',
                noLabel: 'Nope'
            })) {
                navigate(`/done/${id}`);
            }
        } catch (err) {

        }
    }

    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header className={`${baseClassName}__header`}>
            <h1>To-Dot</h1>
        </Screen.Header>
        <Screen.Content className={`${baseClassName}__content`} loading={loading}>
            {toDot && (<>
                <div className={`${baseClassName}__created`}>
                    <div className={`${baseClassName}__created-label`}>Added By:</div>
                    <div className={`${baseClassName}__created-by`}>
                        @{toDot.createdBy}
                    </div>
                    <div className={`${baseClassName}__created-label`}>Added On:</div>
                    <FormattedDate className={`${baseClassName}__created-dtm`}
                                   date={toDot.createdDtm}
                                   format={`MMM do yyyy 'at' h:mm aaaa`}/>
                </div>
                <div className={`${baseClassName}__title`}>{toDot.summary}</div>

                <Markdown className={`${baseClassName}__description`}>{toDot.details}</Markdown>

                <div className={`${baseClassName}__images`}>
                    {toDot.images.map(image => (
                        <div className={`${baseClassName}__image`} key={image}>
                            <img src={`${image}?tr=w-450`}/>
                        </div>
                    ))}
                </div>

                {toDot.toDone && (<>
                    <h2>To-Done</h2>
                    <div className={`${baseClassName}__created`}>
                        <div className={`${baseClassName}__created-label`}>Done By:</div>
                        <div className={`${baseClassName}__created-by`}>
                            @{toDot.toDone.createdBy}
                        </div>
                        <div className={`${baseClassName}__created-label`}>Done On:</div>
                        <FormattedDate className={`${baseClassName}__created-dtm`}
                                       date={toDot.toDone.createdDtm}
                                       format={`MMM do yyyy 'at' h:mm aaaa`}/>
                    </div>
                    <Markdown className={`${baseClassName}__to-done`}>{toDot.toDone.description}</Markdown>
                </>)}
                <div className={`${baseClassName}__images`}>
                    {toDot.toDone?.images?.map(image => (
                        <div className={`${baseClassName}__image`} key={image}>
                            <img src={`${image}?tr=w-450`}/>
                        </div>
                    ))}
                </div>
            </>)}
        </Screen.Content>
        <Screen.Actions>

            {(!loading && !toDot?.toDone) ? (<>
                <Button icon={'close'} onClick={() => navigate(referrer)}/>
                <Button icon={'check'} onClick={handleCompleteClick}>Complete</Button>
            </>) : (
                <Button icon={'close'} onClick={() => navigate(referrer)}>Close</Button>
            )}
        </Screen.Actions>
    </Screen>
}

export default ToDotViewer;