import './Button.scss';
import React, {ReactNode, useEffect, useState} from "react";
import classnames from "classnames";
import {Link, LinkProps} from "react-router-dom";

const baseClassName = 'button';

export type LinkButtonProps = LinkProps & {
    label?: ReactNode;
    type?: 'default' | 'negative' | 'affirmative' | string;
    icon?: string;
    disabled?: boolean;
}

export function LinkButton({
                               className,
                               to,
                               type = 'default',
                               disabled,
                               icon,
                               children,
                               label = children,
                               ...props
                           }: LinkButtonProps) {
    return <Link role={'button'}
                 to={to as string}
                 className={classnames(baseClassName, className, `${baseClassName}--${type}`, {
                     [`${baseClassName}--disabled`]: disabled,
                     [`${baseClassName}--icon-only`]: icon && !label,
                 })}
                 {...props}>
        <div className={`${baseClassName}__content`}>
            {label && <div className={`${baseClassName}__label`}>{label}</div>}
            {icon && (
                <i className={classnames(`${baseClassName}__icon`, `${baseClassName}__icon--${icon}`)}/>
            )}
        </div>
    </Link>
}

export default LinkButton;