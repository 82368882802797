import './SetUsername.scss';
import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import classnames from "classnames";
import Screen from "@/components/Screen/Screen";
import {Button} from "@/components/Button";
import {useAccount} from "@services/account.service";
import {useApp} from "@/contexts/AppContext";
import {useLocation, useNavigate} from "react-router-dom";

const baseClassName = 'set-username';

export type SetUsernameProps = {
    className?: string;
    children?: ReactNode;
}

export function SetUsername({className, ...props}: SetUsernameProps) {

    const {setUsername: setAccountUsername} = useAccount();
    const {user, refreshUser} = useApp();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {referrer = '/'} = useLocation().state ?? {};
    const [username, setUsername] = useState<string>('');

    useEffect(() => {
        if(user?.username) {
            navigate(referrer === pathname ? '/' : referrer , {
                replace: true
            })
        }
    }, [user])

    function handleUsernameChange(e: ChangeEvent<HTMLInputElement>) {
        setUsername(e.target.value);
    }

    async function save() {
        await setAccountUsername(username.trim());
        await refreshUser();
    }

    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header className={`${baseClassName}__header`}>
            <h1>Choose A Username</h1>
        </Screen.Header>
        <Screen.Content className={`${baseClassName}__content`}>
            <input value={username} name="username" type="text" onChange={handleUsernameChange} placeholder={'Choose A Username'}/>
        </Screen.Content>
        <Screen.Actions stacked>
            <Button onClick={save} disabled={!username.trim()}>Save</Button>
        </Screen.Actions>
    </Screen>
}

export default SetUsername;