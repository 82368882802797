import './ToDotQrCode.scss';
import React, {ReactNode, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import QrCodeStyling from 'qr-code-styling';
import logo from '../../images/qr-logo.svg';
import heart from '../../images/qr-heart-check.svg';
import honey from '../../images/qr-honey.svg';

const baseClassName = 'to-dot-qr-code';

export type ToDotQrCodeProps = {
    url: string;
    className?: string;
    children?: ReactNode;
}

export function ToDotQrCode({url, className, ...props}: ToDotQrCodeProps) {

    const qrCode = useRef<QrCodeStyling>(new QrCodeStyling({
        data: url,
        height: 300,
        width: 300,
        image: honey,
        imageOptions: {
            hideBackgroundDots: false,
            imageSize: .5
        },
        dotsOptions: {
            type: 'dots'
        },
        cornersDotOptions: {
            type: 'square'
        },
        cornersSquareOptions: {
            type: 'square'
        }
    }))
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        qrCode.current.append(ref.current!);
    }, []);

    useEffect(() => {
        qrCode.current.update({data: url})
        // ref.current?.firstChild?.
    }, [url])

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__frame`} ref={ref}></div>
    </div>
}

export default ToDotQrCode;
