import './scss/index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import {AppProvider} from "./contexts/AppContext";
import {initializeApp} from "@firebase/app";
import firebase from 'firebase/compat/app';
import {firebaseConfig} from "./config/firebase.config";
import ModalProvider from "./contexts/ModalContext";

initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ModalProvider>
            <AppProvider>
                <RouterProvider router={router}/>
            </AppProvider>
        </ModalProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
