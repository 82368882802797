import './SetupWizard.scss';
import React, {ReactNode, useEffect} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {createBrowserHistory} from "history";
import {addBase64Images, addToDotImageFiles, createToDot} from "../../services/api.service";
import useState from 'react-usestateref';
import {useModals} from "../Modal";

const baseClassName = 'setup-wizard';

export type SetupWizardProps = {
    className?: string;
    children?: ReactNode;
}
export type SetupWizardContext = {
    onStepComplete: (step: string, data: Partial<ToDotValues>) => Promise<void>;
    values: ToDotValues,
}

const STEP_MAPPING: Record<string, string> = {
    scan: '/create/details',
    details: '/create/pics',
    // pics: '/',
}

type ToDotValues = {
    _id: string;
    summary: string;
    details: string;
    images: File[];
}

export function SetupWizard({className, ...props}: SetupWizardProps) {

    const navigate = useNavigate();
    const history = createBrowserHistory();
    const {confirm} = useModals();
    const [toDot, setToDot, toDotRef] = useState<ToDotValues>({
        _id: '',
        summary: '',
        details: '',
        images: []
    })

    const [context] = useState<SetupWizardContext>({

        values: toDot,
        async onStepComplete(step: string, data: any) {

            setToDot(current => ({
                ...current,
                ...data
            }));

            if (STEP_MAPPING[step]) {
                navigate(STEP_MAPPING[step], {
                    replace: true
                });
                return;
            }

            const {_id, images, ...values} = toDotRef.current;

            await createToDot(_id, values)
            await addToDotImageFiles(_id, images)

            navigate('/', {
                replace: true
            });
        }
    })

    return <Outlet context={context}/>
}

export default SetupWizard;