import './SignIn.scss';
import React, {ChangeEvent, ReactNode, useState} from "react";
import classnames from "classnames";
import {useLocation, useNavigate} from "react-router-dom";
import {useAccount} from "@services/account.service";
import Screen from "../../Screen/Screen";
import {Button} from "../../Button";
import LinkButton from "../../Button/LinkButton";
import {Input} from "@/components/Input";
import {validate} from "email-validator";

const baseClassName = 'sign-in';

export type SignInProps = {
    className?: string;
    children?: ReactNode;
}

export function SignIn({className, ...props}: SignInProps) {

    const {referrer = '/'} = useLocation().state ?? {};
    const navigate = useNavigate();
    const {login, signInWithGoogle} = useAccount();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    function handleEmailChange({target}: ChangeEvent<HTMLInputElement>) {
        setEmail(target.value);
    }

    function handlePasswordChange({target}: ChangeEvent<HTMLInputElement>) {
        setPassword(target.value);
    }

    async function handleLoginClick() {
        try {
            await login(email.trim(), password.trim());
            navigate(referrer, {
                replace: true,
                state: {
                    referrer: '/'
                }
            });
        } catch (err) {
            alert('Sign In Failed.  Try Again.')
        }
    }

    async function handleGoogleSignUpClick() {
        try {
            await signInWithGoogle();
            navigate(referrer, {
                replace: true,
                state: {
                    referrer: '/'
                }
            });
        } catch (err) {
            alert('Sign In Failed.  Try Again.')
        }
    }

    function missingData(): boolean {
        return !(validate(email) && password.trim().length > 4);
    }


    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header className={`${baseClassName}__header`}>
            <h1>Sign In</h1>
        </Screen.Header>
        <Screen.Content className={`${baseClassName}__content`}>
            <Input value={email} type="email"
                   label={'Email'}
                   autoCapitalize="none" autoCorrect="off" autoComplete="email" onChange={handleEmailChange}
                   placeholder={'Email'}/>
            <Input value={password}
                   label={'Password'}
                   onEnter={handleLoginClick}
                   type="password" onChange={handlePasswordChange} placeholder={'Password'}/>
        </Screen.Content>
        <Screen.Actions stacked>
            <Button onClick={handleLoginClick} disabled={missingData()}>Sign In</Button>
            <Button icon={'google'} onClick={handleGoogleSignUpClick}>Sign In With Google</Button>
            <LinkButton to={'/sign_up'} replace state={{referrer}}>No Account? Sign Up</LinkButton>
        </Screen.Actions>
    </Screen>
}

export default SignIn;
