import './DetailsStep.scss';
import React, {ChangeEvent, ReactNode} from "react";
import useState from 'react-usestateref';
import classnames from "classnames";
import {useNavigate, useOutletContext} from "react-router-dom";
import Screen from "../../Screen/Screen";
import {SetupWizardContext} from "../SetupWizard";
import {Input} from "../../Input";
import {SpeechTextArea} from "../../TextArea";
import {Button} from "../../Button";

const baseClassName = 'details-step';

export type DetailsStepProps = {
    className?: string;
    children?: ReactNode;
}

export function DetailsStep({className, ...props}: DetailsStepProps) {

    const navigate = useNavigate();
    const {onStepComplete} = useOutletContext<SetupWizardContext>();
    const [summary, setSummary] = useState<string>('');
    const [details, setDetails] = useState<string>('');

    function handleNextClick() {

        onStepComplete('details', {
            summary,
            details,
        });
    }

    function handleDetailsChange(e: ChangeEvent<HTMLTextAreaElement>) {
        setDetails(e.target.value);
    }

    function handleSummaryChange(e: ChangeEvent<HTMLInputElement>) {
        setSummary(e.target.value);
    }

    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header className={`${baseClassName}__header`}>
            <h1>What Needs To Be Done?</h1>
        </Screen.Header>
        <Screen.Content className={`${baseClassName}__content`}>
            <Input label={'Summary'} type="text" value={summary} onChange={handleSummaryChange}/>
            <SpeechTextArea className={`${baseClassName}__description`}
                            label={'Details'}
                            value={details} onChange={handleDetailsChange}/>
        </Screen.Content>
        <Screen.Actions className={`${baseClassName}__actions`}>
            <Button icon={'cancel'} onClick={() => navigate(-1)}/>
            <Button icon={'next'} onClick={handleNextClick}>Next</Button>
        </Screen.Actions>
    </Screen>
}

export default DetailsStep;