import './Screen.scss';
import React, {FC, HTMLAttributes} from "react";
import classnames from "classnames";

const baseClassName = 'screen';

export type ScreenProps = HTMLAttributes<HTMLDivElement> & {}

export const Screen: FC<ScreenProps> & {
    Header: typeof ScreenHeader;
    Content: typeof ScreenContent;
    Actions: typeof ScreenActions;
    EmptyState: typeof ScreenEmptyState;
} = ({children, className, ...props}) => {
    return <div className={classnames(baseClassName, className)} {...props}>
        {children}
    </div>
}

export type ScreenHeaderProps = HTMLAttributes<HTMLDivElement> & {}
export const ScreenHeader: FC<ScreenHeaderProps> = ({children, className, ...props}) => {

    return (
        <div className={classnames(`${baseClassName}-header`, className)}>
            {children}
        </div>
    );
}


export type ScreenContentProps = HTMLAttributes<HTMLDivElement> & {
    loading?: boolean;
}
export const ScreenContent: FC<ScreenContentProps> = ({children, loading, className, ...props}) => {

    if(loading) return <></>;

    return (
        <div className={classnames(`${baseClassName}-content`, className)}>
            {children}
        </div>
    );
}


export type ScreenActionsProps = HTMLAttributes<HTMLDivElement> & {
    stacked?: boolean;
}
export const ScreenActions: FC<ScreenActionsProps> = ({children, stacked, className, ...props}) => {

    const baseClassName = 'screen-actions'
    return (
        <div className={classnames(baseClassName, className, {
            [`${baseClassName}--stacked`]: stacked
        })}>
            {children}
        </div>
    );
}

export type ScreenEmptyState = HTMLAttributes<HTMLDivElement> & {
    show: boolean;
    icon?: string;
}
export const ScreenEmptyState: FC<ScreenEmptyState> = ({children, show, icon, className, ...props}) => {

    if (!show) return <></>

    return (
        <div className={classnames(`${baseClassName}-empty-state`, className)}>
            {children}
        </div>
    );
}

Screen.Header = ScreenHeader;
Screen.Content = ScreenContent;
Screen.Actions = ScreenActions;
Screen.EmptyState = ScreenEmptyState;

export default Screen;