import './ToDotCard.scss';
import React, {HTMLAttributes, ReactNode, useEffect, useState} from "react";
import classnames from "classnames";

const baseClassName = 'to-dot-card';

export type ToDotCardProps = Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'> & {
    toDot: ToDot;
    onSelect?: (toDot: ToDot) => void;
}

export function ToDotCard({className, toDot, onSelect, onClick, ...props}: ToDotCardProps) {

    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
        onSelect?.(toDot);
        onClick?.(e);
    }

    return <div className={classnames(baseClassName, className, {
        [`${baseClassName}--complete`]: Boolean(toDot.toDone)
    })}
                onClick={handleClick}
                {...props}>
        <div className={`${baseClassName}__thumbnail`}>
            <img src={`${toDot.images[0]}?tr=w-50,h-50`}/>
        </div>
        <div className={`${baseClassName}__summary`}>
            <div className={`${baseClassName}__title`}>{toDot.summary}</div>
            {/*<div className={`${baseClassName}__description`}>{toDot.details}</div>*/}
        </div>
    </div>
}

export default ToDotCard;