import './Home.scss';
import React, {ReactNode, useEffect, useState} from "react";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {useApp} from "../../contexts/AppContext";
import {useAccount} from "../../services/account.service";
import Screen from "../Screen/Screen";
import TextLogo from "../../images/logo/check-name-hollow.svg";
import LinkButton from "../Button/LinkButton";
import {useModals} from "../Modal";
import {Button} from "../Button";

const baseClassName = 'home';

export type HomeProps = {
    className?: string;
    children?: ReactNode;
}

export function Home({className, ...props}: HomeProps) {
    const {user} = useApp();
    const {logout} = useAccount();
    const {alert, confirm} = useModals();

    async function handleSignOut() {

        if (await confirm({
            title: 'Are you sure?',
            content: <>
                <p>Do you really want to sign out?</p>
                <p>We'll miss you. :(</p>
            </>
        })) {
            await logout()
        }
    }

    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header className={`${baseClassName}__header`}>
            <h1>@{user!.username}</h1>
        </Screen.Header>
        <Screen.Content className={`${baseClassName}__content`}>
            <LinkButton to={'/create'} icon={'add'}>Create A To-Dot</LinkButton>
            <LinkButton to={'/scan'} icon={'scan'}>Scan A To-Dot</LinkButton>
            <LinkButton to={'/my_todots'} icon={'list'}>My To-Dots</LinkButton>
            <LinkButton to={'/print'} icon={'print'}>Print My To-Dots</LinkButton>
        </Screen.Content>
        <Screen.Actions className={`${baseClassName}__actions`}>
            <Button onClick={handleSignOut}>Sign Out</Button>
        </Screen.Actions>
    </Screen>
}

export default Home;