import './Input.scss';
import React, {ReactNode, useEffect, useState, HTMLInputTypeAttribute} from "react";
import classnames from "classnames";

const baseClassName = 'input';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    onEnter?(): void;
}

export function Input({className, onEnter, label, ...props}: InputProps) {

    function handleKeyDown(e: React.KeyboardEvent) {
        if(e.key === "Enter") {
            onEnter?.();
        }
    }

    return <label className={classnames(baseClassName, className)}>
        <input className={`${baseClassName}__input`} {...props} onKeyDown={handleKeyDown}/>
        {label && <div className={`${baseClassName}__label`}>{label}</div>}
    </label>
}

export default Input;
