import './Modal.scss';
import React, {useContext, useRef} from 'react';
import {create as createModal, InstanceProps} from 'react-modal-promise';
import {AnimatePresence, motion, Transition, Variants} from "framer-motion";
import {Button} from "../Button";
import classnames from "classnames";

export type ModalType = 'default' | 'error' | 'info' | 'warning'

export type ModalProps = {
    onClick?: Function;
    heading?: string;
    type?: ModalType;
    onClose?: Function;
    closeOnBlur?: boolean;
    closeButtonText?: string;
    show?: boolean;
    [prop: string]: any;
}
const baseClassName = 'modal';
const Modal = ({
                   onClick = () => {
                   },
                   onClose = onClick,
                   heading,
                   type = 'default',
                   closeOnBlur,
                   closeButtonText,
                   show,
                   className,
                   children,
                   ...props
               }: ModalProps) => {

    const ref = useRef<HTMLDivElement>(null);

    const wrapperclassnames = classnames('modal-wrapper', className, {
        [`modal-wrapper--${type}`]: true,
    });

    const headerclassnames = classnames(`modal-header`, {
        [`modal-header--with-title`]: heading
    });

    const modalclassnames = classnames(baseClassName, {
        [`${baseClassName}--${type}`]: true,
    });

    const onWrapperClick = (event: React.MouseEvent) => {
        if (closeOnBlur && !ref.current?.contains(event.target as Node)) {
            return onClose?.();
        }
    }

    return (
        <div className={wrapperclassnames} {...props} onClick={onWrapperClick}>
            <div className={modalclassnames} ref={ref}>
                <header className={`modal-header`}>
                    <h1 className={`modal-header__title`}>{heading}</h1>
                    <i className={`modal-header__close`} onClick={() => onClose?.()}/>
                </header>
                <main className={'modal-content'}>
                    {children}
                </main>
            </div>
        </div>
    );
};

export type ModalMaskProps = React.HTMLAttributes<HTMLDivElement> & {
    show: boolean;
}

export function ModalMask({className, show, children, ...props}: ModalMaskProps) {
    if (!show) return <></>;

    return (
        <div className={classnames('modal__loading-mask', className)} {...props}>
            <svg
                className={'modal__loading-spinner'}
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle pathLength={1} cx="50" cy="50" r="45"/>
            </svg>
            {children && <div className={'modal__loading-text'}>{children}</div>}
        </div>
    );
}

export type ModalContextValue = {
    isOpen: boolean;
    resolve: (value?: any) => void;
    reject: (value?: any) => void;
}
export const ModalContext = React.createContext<ModalContextValue | null>(null);
export const useModalContext = () => React.useContext(ModalContext);

export const openModal = createModal(({
                                          isOpen,
                                          onResolve,
                                          onReject,
                                          title,
                                          content,
                                          className
                                      }: ModalProps & InstanceProps<any>) => {
    return (
        <Modal className={className} heading={title} show={isOpen} onClose={onResolve}>
            <ModalContext.Provider value={{
                resolve: onResolve,
                reject: onReject,
                isOpen
            }}>
                {content}
            </ModalContext.Provider>
        </Modal>
    );
});

export type ConfirmOptions = {
    type?: ModalType;
    title?: string;
    content?: string | JSX.Element;
    yesLabel?: string;
    noLabel?: string;
    closeButton?: boolean;
    [prop: string]: any;
}

type ConfirmFunction = {
    (options: ConfirmOptions): Promise<boolean>
}

export const confirm: ConfirmFunction = createModal(
    ({
         onResolve,
         title,
         content,
         yesLabel = 'Yes',
         noLabel = 'No',
         closeButton = false,
         className,
         ...props
     }: ConfirmOptions & InstanceProps<any>) => {

        const baseClassName = 'confirm-modal';
        const variants: Variants = {
            in: {
                opacity: 1,
                y: 0
            },
            out: {
                opacity: 0,
                y: '100px'
            }
        }

        const timing: Transition = {
            duration: .1
        }

        return (
            <AnimatePresence>
                <div className={classnames('modal-wrapper', 'modal-wrapper--visible')}>
                    <motion.div
                        className={classnames(baseClassName, className)}
                        variants={variants}
                        initial="out"
                        animate="in"
                        exit="out"
                        transition={timing}>
                        <header className={`${baseClassName}__header`}>
                            <h1 className={`${baseClassName}__title`}>{title}</h1>
                        </header>
                        <main className={`${baseClassName}__content`}>{content}</main>
                        <footer className={`${baseClassName}__footer`}>
                            <Button onClick={() => onResolve(false)} label={noLabel}/>
                            <Button onClick={() => onResolve(true)} label={yesLabel}/>
                        </footer>
                    </motion.div>
                </div>
            </AnimatePresence>
        );
    }
);

export type AlertOptions = {
    type?: ModalType;
    title?: string | JSX.Element;
    content: string | JSX.Element;
    okLabel?: string;
    showCloseButton?: boolean;
    [prop: string]: any;
}

type AlertFunction = {
    (options: AlertOptions): Promise<boolean>
}

export const alert: AlertFunction = createModal(({
                                                     onResolve,
                                                     type,
                                                     title,
                                                     content,
                                                     okLabel = 'Ok',
                                                     className,
                                                     ...props
                                                 }: AlertOptions & InstanceProps<boolean>) => {


    const baseClassName = 'alert-modal';
    const variants: Variants = {
        in: {
            opacity: 1,
            y: 0
        },
        out: {
            opacity: 0,
            y: '100px'
        }
    }

    const timing: Transition = {
        duration: .1
    }
    return (
        <AnimatePresence>
            <div className={classnames('modal-wrapper', 'modal-wrapper--visible', 'modal-wrapper--alert')}>
                <motion.div
                    className={classnames(baseClassName, className, {
                        [`${baseClassName}--${type}`]: Boolean(type),
                    })}
                    variants={variants}
                    initial="out"
                    animate="in"
                    exit="out"
                    transition={timing}>
                    {Boolean(title) && (
                        <header className={`alert-modal__header`}>
                            <h1 className={`${baseClassName}__title`}>
                                {title}
                            </h1>
                        </header>
                    )}
                    <main className={`${baseClassName}__content`}>{content}</main>
                    <footer className={`${baseClassName}__footer`}>
                        <Button onClick={() => onResolve(true)}>{okLabel}</Button>
                    </footer>
                </motion.div>
            </div>
        </AnimatePresence>
    );
});

export type ErrorModalProps = {
    title: string;
    content: string | JSX.Element;
    displayErrorId?: boolean;
    errorId?: string;
}

export async function openErrorModal({title, content, displayErrorId = false, errorId}: ErrorModalProps) {
    let errorInfo;

    if (displayErrorId && errorId) {
        // add a dash and convert to uppercase
        const formattedErrorId = errorId.replace(/^(....)/, '$1-').toUpperCase();

        errorInfo = (
            <div className={'modal-content__reference-code'}>
                Your reference code is <em className={'error-id'}>{formattedErrorId}</em>.
            </div>
        );
    }

    return openModal({
        title,
        content: (
            <>
                {content}
                {errorInfo}
            </>
        ),
    });
}

export const useModals = () => ({
    openModal,
    openErrorModal,
    confirm,
    alert,
});

export default Modal;
