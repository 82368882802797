import Layout15Square from "../../images/layouts/22805.webp";
import Layout20Square from "../../images/layouts/22806.webp";
import Layout20Round from "../../images/layouts/22807.webp";
import Layout4 from "../../images/layouts/22830.webp";
import Layout5 from "../../images/layouts/60504.webp";
import Layout6 from "../../images/layouts/94101.webp";
import Layout7 from "../../images/layouts/94104.webp";
import Layout8 from "../../images/layouts/94106.webp";
import Layout9 from "../../images/layouts/94500.webp";
import Layout10 from "../../images/layouts/94506.webp";

export type Layout = {
    top: number;
    left: number;
    rowGap: number;
    colGap: number;
    rows: number;
    columns: number;
    size: number;
    shape: 'square' | 'round';
    template?: string;
}

export const layouts: Layout[] = [
    {
        columns: 6,
        rows: 8,
        size: 1.0,
        top: .625,
        left: .625,
        rowGap: .25,
        colGap: .25,
        shape: "square",
        template: 'Avery 69993',
    },
    {
        columns: 6,
        rows: 8,
        size: 1.0,
        top: .625,
        left: .625,
        rowGap: .25,
        colGap: .25,
        shape: "round",
        template: 'Avery 36485',
        // Presta 36485, Presta 36486, Presta 36487, Presta 36488, Presta 36489, Presta 36529, Presta 36530, Presta 36531, Presta 36532, Presta 36533, Presta 36579, Presta 36580, Presta 36581, Presta 36582, Presta 36583
    },
    {
        columns: 4,
        rows: 6,
        size: 1.5,
        shape: "square",
        top: .5,
        left: .7799,
        rowGap: .2,
        colGap: .3132,
        template: 'Avery 22805'
    },
    {
        columns: 4,
        rows: 5,
        size: 1.5,
        top: .75,
        left: .5,
        rowGap: .5,
        colGap: .5,
        shape: "round",
        // 8293, 8324, Presta 36490, Presta 36491, Presta 36492, Presta 36493, Presta 36494, Presta 36534, Presta 36535, Presta 36536, Presta 36537, Presta 36538, Presta 36584, Presta 36585, Presta 36586, Presta 36587, Presta 36588
    },
    {
        columns: 3,
        rows: 4,
        size: 2.0,
        top: .625,
        left: .625,
        rowGap: .625,
        colGap: .5826,
        shape: 'square',
        template: 'Avery 22806',
        // 22565, 22806, 22816, 22853, 22921, 22922, 22960, 5908, 64503, 64510, 80510, Presta 36475, Presta 36476, Presta 36477, Presta 36478, Presta 36479
    },
    {
        columns: 3,
        rows: 4,
        size: 2.0,
        top: .625,
        left: .625,
        rowGap: .5826,
        colGap: .625,
        shape: 'round',
        template: 'Avery 22612',
        // 22205, 22612, 22817, 22825, 22877, 22921, 22923, 22933, 22961, 41464, 41494, 5907, 64502, 80510, 92807, Presta 36495, Presta 36496, Presta 36497, Presta 36498, Presta 94501
    },
    {
        columns: 3,
        rows: 3,
        size: 2.5,
        top: .625,
        left: .3125,
        rowGap: 1.125,
        colGap: .1882,
        shape: 'round',
        template: 'Avery 22562'
        // 22562, 22830, 22926, 22963, 27953, 41462, 41493, 72830
    },
    {
        columns: 3,
        rows: 3,
        size: 2.5,
        top: 1,
        left: .325,
        rowGap: .75,
        colGap: .125,
        shape: 'square',
        template: 'Avery 94104'
    },
    // {
    //     rows: 2,
    //     columns: 2,
    //     size: 4.0,
    //     top: .5,
    //     left: .7799,
    //     rowGap: .2,
    //     colGap: .3132,
    //     shape: "square",
    // },
    // {
    //     columns: 2,
    //     rows: 3,
    //     size: 3.0,
    //     top: .5,
    //     left: .7799,
    //     rowGap: .2,
    //     colGap: .3132,
    //     shape: 'square'
    // },
];