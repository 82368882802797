import './ScanStep.scss';
import React, {ReactNode, useRef} from "react";
import classnames from "classnames";
import {useNavigate, useOutletContext} from "react-router-dom";
import {SetupWizardContext} from "../SetupWizard";
import Screen from "../../Screen/Screen";
import QrInput, {QrInputType, ValueChangeEvent} from "../../QrInput/QrInput";
import {getMyToDot} from "../../../services/api.service";
import {Button} from "../../Button";
import {useApp} from "../../../contexts/AppContext";
import LinkButton from "../../Button/LinkButton";
import {useModals} from "../../Modal";

const baseClassName = 'scan-step';

export type ScanStepProps = {
    className?: string;
    children?: ReactNode;
}

export function ScanStep({className, ...props}: ScanStepProps) {

    const navigate = useNavigate();
    const {user} = useApp();
    const {confirm, alert} = useModals();
    const context = useOutletContext<SetupWizardContext>();
    const scannerRef = useRef<QrInputType | null>(null);

    async function handleScanComplete(e: ValueChangeEvent<string>) {

        const [url, host, _id] = e.value.match(/^https:\/\/(.*?)\/.*?([\w,-]+)$/) ?? [];

        try {
            const toDot = await getMyToDot(_id);
            if (toDot.used && !(await confirm({
                title: 'Are you sure?',
                content: <>
                    <p>This To-Dot has already been used.</p>
                    <p>Do you want to replace it?</p>
                </>
            }))) {
                scannerRef.current?.start();
                return;
            }

            await context.onStepComplete('scan', {
                _id
            })
        } catch (err: any) {
            if ([401, 403].includes(err.response?.status)) {
                await alert({
                    type: 'error',
                    title: <>Hold On A Minute</>,
                    content: 'You can only create To-Dots using your own To-Dot stickers.'
                });
                scannerRef.current?.start();
                return;
            }
        }
    }

    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header className={`${baseClassName}__header`}>
            <h1>Scan</h1>
            <h2>Scan one of your To-Dot stickers to get started.</h2>
        </Screen.Header>
        <Screen.Content>
            {user!.actions?.stickersPrinted && (
                <QrInput ref={scannerRef} onChange={handleScanComplete} autoStart={true} stopAfterScan={true}/>
            )}
            <Screen.EmptyState show={!user!.actions?.stickersPrinted}>
                <p>You haven't printed your To-Dot stickers yet.</p>
                <p>Tap the "Print To-Dots" button to print them now.</p>
            </Screen.EmptyState>
        </Screen.Content>
        <Screen.Actions className={`${baseClassName}__actions`}>
            <Button icon={'cancel'} onClick={() => navigate(-1)}>Cancel</Button>
            <LinkButton icon={'print'} to={'/print'}>Print To-Dots</LinkButton>
        </Screen.Actions>
    </Screen>
}

export default ScanStep;