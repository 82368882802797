import {createBrowserRouter, Navigate, RouteObject, useLocation, useNavigate} from 'react-router-dom'
import Home from "./components/Home/Home";
import QrInput from "./components/QrInput/QrInput";
import SignIn from "./components/auth/SignIn/SignIn";
import SignUp from "./components/auth/SignUp/SignUp";
import SetupWizard from "./components/Wizard/SetupWizard";
import {ReactNode, useEffect} from "react";
import {useApp} from "./contexts/AppContext";
import ScanStep from "./components/Wizard/ScanStep/ScanStep";
import DetailsStep from "./components/Wizard/DetailsStep/DetailsStep";
import PicturesStep from "./components/Wizard/PicturesStep/PicturesStep";
import MyToDots from "./components/MyToDots/MyToDots";
import ToDotPrinter from "./components/ToDotPrinter/ToDotPrinter";
import {ToDotViewer} from "./components/ToDotViewer";
import {ToDotScanner} from "./components/ToDotScanner";
import {ToDoneEditor} from "./components/ToDoneEditor";
import {SetUsername} from "@/components/auth/SetUsername";

export const router = createBrowserRouter(toRoutes([
    {
        path: '/',
        element: <Home/>
    }, {
        path: '/todot/:id',
        element: <ToDotViewer/>
    }, {
        path: '/print',
        element: <ToDotPrinter/>
    }, {
        path: '/my_todots',
        element: <MyToDots/>
    }, {
        path: '/scan',
        element: <ToDotScanner/>
    }, {
        path: '/sign_in',
        element: <SignIn/>,
        requireAccount: false
    }, {
        path: '/sign_up',
        element: <SignUp/>,
        requireAccount: false
    }, {
        path: '/username',
        element: <SetUsername/>,
    }, {
        path: '/done/:id',
        element: <ToDoneEditor/>,
    },{
        path: '/create',
        element: <SetupWizard/>,
        children: [
            {
                index: true,
                element: <ScanStep/>
            }, {
                path: 'scan',
                element: <ScanStep/>
            }, {
                path: 'details',
                element: <DetailsStep/>
            }, {
                path: 'pics',
                element: <PicturesStep/>
            }, {
                path: '*',
                element: <Navigate to={'/create'} replace/>
            }
        ]
    }, {
        path: '*',
        element: <Navigate to={'/'} replace/>
    }
]));

export type RouteOptions = RouteObject & {
    requireAccount?: boolean;
}

export type RequireAccountProps = {
    required: boolean;
    children: ReactNode;
}

function RequireAccount({required = true, children}: RequireAccountProps) {

    const {firebaseUser, user} = useApp();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        if(firebaseUser && !user?.username) {
            navigate('/username', {
                replace: true,
                state: {
                    referrer: location.pathname
                }
            });
        } else if (!firebaseUser && required) {
            navigate('/sign_in', {
                replace: true,
                state: {
                    referrer: location.pathname
                }
            });
        } else if (user && !required) {
            navigate('/', {replace: true});
        }
    }, [user]);

    if ((!firebaseUser && required) || (user && !required)) {
        return <></>
    }

    return <>{children}</>;
}

function toRoutes(configs: (RouteObject & RouteOptions)[]): RouteObject[] {

    return configs.map(config => ({
        ...config,
        element: <RequireAccount required={config.requireAccount !== false}>
            {config.element}
        </RequireAccount>
    }))
}