import './PrintLayoutPreview.scss'
import React, {useState} from "react";
import classnames from "classnames";
import {Layout} from "./Layouts";

export type PrintLayoutPreviewProps = React.HTMLAttributes<HTMLDivElement> & {
    layout: Layout;
    active?: boolean;
}

export function PrintLayoutPreview({className, layout, active, ...props}: PrintLayoutPreviewProps) {
    const baseClassName = 'print-layout-preview';
    const [dots] = useState<any>(Array.from({length: layout.rows * layout.columns}));

    return <div className={classnames(baseClassName, className, {
        [`${baseClassName}--active`]: active
    })} {...props} style={{
        '--rows': layout.rows,
        '--cols': layout.columns
    } as React.CSSProperties}>
        {dots.map((_: never, i: number) => <div key={i} className={classnames(`${baseClassName}__dot`, {
            [`${baseClassName}__dot--round`]: layout.shape === 'round'
        })}/>)}
        <div className={`${baseClassName}__size`}>{layout.size} in</div>
    </div>
}