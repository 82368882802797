import './QrInput.scss';
import React, {HTMLAttributes, SyntheticEvent, useEffect, useImperativeHandle, useRef, useState} from "react";
import classnames from "classnames";
import QrScanner from "qr-scanner";
import {Button} from "../Button";

const baseClassName = 'qr-input';


export type ValueChangeEvent<T> = {
    value: T;
    name?: string;
    originalEvent?: Event | SyntheticEvent;
}

type QrCodeValue = {
    url: string;
    id: string;
}

export type ScannerProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
    name?: string;
    value?: string;
    onChange?: (e: ValueChangeEvent<string>) => void;
    autoStart?: boolean;
    onDecodeError?: (error: Error | string) => void;
    calculateScanRegion?: (video: HTMLVideoElement) => QrScanner.ScanRegion;
    camera?: QrScanner.FacingMode | QrScanner.DeviceId;
    maxScansPerSecond?: number;
    highlightScanRegion?: boolean;
    highlightCodeOutline?: boolean;
    /** just a temporary flag until we switch entirely to the new api */
    returnDetailedScanResult?: true;
    stopAfterScan?: boolean
}

export type QrInputType = {
    value: string;
    start: () => void;
    stop: () => void
};

export const QrInput = React.forwardRef<QrInputType, ScannerProps>(({
                      className,
                      stopAfterScan = true,
                      name,
                      value,
                      onChange,
    autoStart = true,
                      camera = 'environment',
                      ...props
                  }, ref) => {

    const [url, setUrl] = useState<string>('');
    const scanner = useRef<QrScanner | null>(null);
    const videoRef = useRef<HTMLVideoElement>(null);


    useImperativeHandle(ref, () => ({
        value: url,
        start: () => {
            scanner.current?.start();
        },
        stop: () => {
            scanner.current?.stop();
        }
    }));


    useEffect(() => {

        if (videoRef.current) {
            scanner.current = new QrScanner(videoRef.current, handleResult, {
                onDecodeError: () => {
                },
                preferredCamera: camera,
                highlightCodeOutline: true,
                highlightScanRegion: true,
                // calculateScanRegion:(v)=>{
                //     const smallestDimension = Math.min(v.videoWidth, v.videoHeight);
                //
                //     // Make scan region smaller to match better small qr codes
                //     const scanRegionSize = Math.round(1 / 4 * smallestDimension);
                //
                //     let region:QrScanner.ScanRegion = {
                //         x: Math.round((v.videoWidth - scanRegionSize) / 2),
                //         y: Math.round((v.videoHeight - scanRegionSize) / 2),
                //         width: scanRegionSize,
                //         height: scanRegionSize,
                //     };
                //     return region;
                // }
            });
            if(autoStart) scanner.current.start();
        } else {
            scanner.current = null;
        }
        return () => {
            scanner.current?.stop();
        }
    }, [videoRef]);

    const handleResult = ({data}: QrScanner.ScanResult) => {

        setUrl(url);
        onChange?.({
            value: data,
            name,
        })
        if (stopAfterScan) {
            scanner.current?.stop();
        }
    }

    function handleScanClick() {
        scanner.current?.start();
    }

    return <div className={classnames(baseClassName, className)} {...props}>

        <div className={`${baseClassName}__preview`}>
            <video ref={videoRef}/>
        </div>
        {!autoStart && <Button icon={'scan'} onClick={handleScanClick}>Scan</Button>}
    </div>
});

export default QrInput;
