import './ToDoneEditor.scss';
import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import classnames from "classnames";
import {SpeechTextArea, TextArea} from "../TextArea";
import Screen from "../Screen/Screen";
import {Button} from "../Button";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import LinkButton from "../Button/LinkButton";
import {CapturedImage, ImageChangeEvent, ImageInput} from "../ImageInput";
import {useModals} from "../Modal";
import {addToDotImageFiles, addToDone, createToDot, addToDoneImageFiles} from "../../services/api.service";

const baseClassName = 'to-done-editor';

export type ToDoneEditorProps = {
    className?: string;
    children?: ReactNode;
}

export function ToDoneEditor({className, ...props}: ToDoneEditorProps) {

    const {confirm} = useModals();
    const navigate = useNavigate();
    const {id: toDotId} = useParams();
    const [images, setImages] = useState<CapturedImage[]>([]);
    const [description, setDescription] = useState<string>('');
    const [saving, setSaving] = useState<boolean>(false);

    function handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>) {
        setDescription(e.target.value);
    }

    async function complete() {

        try {
            setSaving(true);
            const toDone = await addToDone(toDotId!, {
                description
            })
            await addToDoneImageFiles(toDone._id, images.map(image => image.file));
            navigate(-1);
        } catch(err) {
            console.log('error')
        } finally {
            setSaving(false);
        }
    }

    async function addImage(e: ImageChangeEvent) {
        if(e.value) {
            setImages(images => [...images, e.value!]);
        }
    }

    async function removeImage(index: number) {
        if(await confirm({
            title: 'Remove Pic',
            content: 'Are you sure you want to remove this pic from your To-Done?'
        })) {
            setImages(image => images.filter((_, i) => i !== index));
        }
    }

    return <Screen className={classnames(baseClassName, className)} {...props}>
        <Screen.Header>
            <h1>Complete To-Dot</h1>
            <h2>Add up to 4 images that show what you did.</h2>
        </Screen.Header>
        <Screen.Content className={`${baseClassName}__content`}>
            <SpeechTextArea
                className={`${baseClassName}__details`}
                value={description}
                onChange={handleDescriptionChange}
                label={'Add Details'}
                placeholder={'What you did?'}/>
            {images.length > 0 && (
                <div className={`${baseClassName}__thumbnails`}>
                    {images.map((image, index) => (
                        <div key={image.url}
                             className={`${baseClassName}__thumbnail`}
                             onClick={() => removeImage(index)}
                        >
                            <img src={image.url}/>
                        </div>
                    ))}
                </div>
            )}
        </Screen.Content>
        <Screen.Actions>
            <Button icon={'cancel'} onClick={() => navigate(-1)}/>
            <ImageInput type={'camera'} onChange={addImage} disabled={images.length >= 4}/>
            <Button icon={'done'} disabled={saving} onClick={complete}>To-Done</Button>
        </Screen.Actions>
    </Screen>
}

export default ToDoneEditor;