import './ImageInput.scss';
import React, {ReactNode, useEffect, useState} from "react";
import classnames from "classnames";

const baseClassName = 'image-input';

export type CapturedImage = {
    file: File;
    url: string;
}

export type ImageChangeEvent = {
    name?: string;
    value: CapturedImage | null;
}

export type ImageInputProps = {
    className?: string;
    name?: string;
    type: 'file' | 'camera';
    disabled?: boolean;
    onChange?: (e: ImageChangeEvent) => void;
    label?: string;
}

export function ImageInput({className, disabled, type, name, onChange, label, ...props}: ImageInputProps) {

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {

        let value: CapturedImage | null = null;
        const [file] = e.target.files ?? [];
        if (file) {
            value = {
                file,
                url: URL.createObjectURL(file),
            }
        }

        onChange?.({
            name,
            value
        });
    }

    return <label className={classnames(baseClassName, className, {
        [`${baseClassName}--disabled`]: disabled,
        [`${baseClassName}--icon-only`]: !label,
    })}>
        <input type={'file'}
               className={`${baseClassName}__input`}
               capture={type === 'camera' ? 'environment' : false}
               onChange={handleChange} accept={'image/jpeg, image/png'} />
        {label && <div className={`${baseClassName}__label`}>{label}</div>}
        <i className={classnames(`${baseClassName}__icon`, `${baseClassName}__icon--${type}`)}/>
    </label>
}

export default ImageInput;