import './Button.scss';
import React, {ReactNode, useEffect, useState} from "react";
import classnames from "classnames";

const baseClassName = 'button';

export type ButtonProps = React.HTMLAttributes<HTMLDivElement> & {
    label?: ReactNode;
    type?: 'default' | 'negative' | 'affirmative' | string;
    icon?: string;
    disabled?: boolean;
}

export function Button({className, disabled, type = 'default', icon, onClick, children, label = children, ...props}: ButtonProps) {

    function handleClick(e: React.MouseEvent<HTMLDivElement>) {

        if (!disabled) {
            onClick?.(e);
        }
    }

    return <div role={'button'}
                className={classnames(baseClassName, className, `${baseClassName}--${type}`, {
                    [`${baseClassName}--disabled`]: disabled,
                    [`${baseClassName}--icon-only`]: icon && !label,

                })}
                onClick={handleClick}
                {...props}>
        <div className={`${baseClassName}__content`}>
            {label && <div className={`${baseClassName}__label`}>{label}</div>}
            {icon && (
                <i className={classnames(`${baseClassName}__icon`, `${baseClassName}__icon--${icon}`)}/>
            )}
        </div>
    </div>
}

export default Button;