import './SpeechCaptureInput.scss';
import React, {ReactNode, useEffect, useRef} from "react";
import useState from "react-usestateref";
import classnames from "classnames";
import {createPortal} from "react-dom";
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition'

const baseClassName = 'speech-capture-input';

export type SpeechCaptureInputProps = {
    className?: string;
    children?: ReactNode;
    onCaptureComplete: (value: string) => void;
    capturingContent?: ReactNode;
}

export function SpeechCaptureInput({
                                       className,
                                       capturingContent = <>
                                           <p>Start speaking</p>
                                           <p>Then tap the screen when you're finished.</p>
                                       </>,
                                       onCaptureComplete,
                                       ...props
                                   }: SpeechCaptureInputProps) {

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    function startCapture() {
        SpeechRecognition.startListening({
            continuous: true,
        });
    }

    function endCapture() {
        SpeechRecognition.stopListening();
        onCaptureComplete?.(transcript);
        resetTranscript();
    }

    return (<>
        <div className={classnames(baseClassName, className)} {...props} onClick={startCapture}>

        </div>
        {listening && createPortal(<div className={`${baseClassName}__overlay`} onClick={endCapture}>
            <div className={`${baseClassName}__capturing-content`}>{capturingContent}</div>
            <p>{transcript}</p>
        </div>, document.body)}
    </>)
}


function sentencify(sentence: string) {
    return sentence.replace(/([.?!']\s*[a-z](?!\s))/g, l => l.toUpperCase())
        .replace(/^([a-z])/, l => l.toUpperCase())
        .replace(/([^.?!])$/, '$1.');
}


export default SpeechCaptureInput;