import React from "react";
import * as DateFns from 'date-fns';

export type FormattedDateProps = React.HTMLAttributes<HTMLTimeElement> & {
    date?: Date | string | number;
    format: string;
};

export function FormattedDate({date, format, ...props}: FormattedDateProps) {
    return <time  {...props}>
        {DateFns.format(new Date(date!), format)}
    </time>
}

export default FormattedDate;