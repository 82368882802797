import {createContext, ReactNode, RefObject, useContext, useEffect, useRef} from "react";
import ModalContainer, { ContainerRef } from "react-modal-promise";

const ModalContext = createContext<RefObject<ContainerRef> | null>(null);
ModalContext.displayName = 'ModalContext';

export const useModalContainer = (): ContainerRef => useContext(ModalContext)!.current!;

export type ModalProviderProps = {
    children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {

    const modalContainerRef = useRef<ContainerRef>(null);

    useEffect(() => {
        // console.log(modalContainerRef.current)
    }, [modalContainerRef.current])

    return (<>
        <ModalContext.Provider value={modalContainerRef}>
            {children}
            <ModalContainer ref={modalContainerRef} exitTimeout={0} enterTimeout={0} />
        </ModalContext.Provider>
    </>
    );
};

export default ModalProvider;
